import axiosInstance from '../../../helpers/backend_helper';

const getAllRecommendations = async () => {
  try {
    const response = await axiosInstance.post('api/v1/recommendations/getAllRecommendations');
    return response.data.data.recommendations;  // Assuming the response structure contains the recommendations in 'data.data.recommendations'
  } catch (error) {
    console.error('Error fetching recommendations:', error);
    throw error;
  }
};

export default getAllRecommendations;
