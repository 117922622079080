import axiosInstance from '../../../helpers/backend_helper';

const createSubscriptionPlan = async (planData) => {
  try {
    const response = await axiosInstance.post('api/v1/admins/createSubscriptionPlan', planData);
    return response.data;
  } catch (error) {
    cconsole.error('Error creating subscription plan:', error.response?.data || error.message);
    throw error;
  }
};

export default createSubscriptionPlan;
