import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';

import getAllRecommendations from 'common/realBackend/recommendation/getAllRecommendation';

// import images
import jobs from "../../assets/images/jobs.png";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../assets/images/users/avatar-4.jpg";
import hero1 from '../../assets/images/hero1.jpg';

// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from "swiper";
import "../../../node_modules/swiper/swiper.scss";

const CandidateSection = () => {
    const [candidates, setCandidates] = useState([]);

    // Fetch the recommendations API
    const fetchRecommendations = async () => {
        try {
            const recommendations = await getAllRecommendations();
            const topCandidates = recommendations.slice(0, 10);  // Get top 10 recommendations
            setCandidates(topCandidates);
        } catch (error) {
            console.error('Error fetching recommendations:', error);
        }
    };

    useEffect(() => {
        fetchRecommendations();
    }, []);

    return (
        <React.Fragment>
            <Col lg={4}>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-3">Top 10 Recommended Job Seekers</h4>
                        <Swiper
                            modules={[Navigation, Pagination, Autoplay]}
                            spaceBetween={50}
                            slidesPerView={1}
                            navigation
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                        >
                            {candidates.map((recommendation, index) => {
                                const candidate = recommendation.candidate;
                                const recommender = recommendation.recommender;

                                return (
                                    <SwiperSlide key={index}>
                                        <div className="bg-light p-3 d-flex mb-3 rounded">
                                            <img 
                                                src={candidate.profileImageUrl || hero1} 
                                                alt={candidate.fullName || 'Hero1'} 
                                                className="avatar-sm rounded me-3" 
                                            />
                                            <div className="flex-grow-1">
                                                <h5 className="font-size-15 mb-2">
                                                    <a href="candidate-overview" className="text-body">
                                                        {candidate.fullName}
                                                    </a>
                                                </h5>
                                            </div>
                                            <div>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle className="btn btn-soft-primary" type="button" id="dropdownMenuButton11">
                                                        <i className="bx bx-dots-vertical-rounded"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu aria-labelledby="dropdownMenuButton11">
                                                        <DropdownItem href="candidate-overview">View Details</DropdownItem>
                                                        <DropdownItem href="#">Download CV</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default CandidateSection;