import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import { isEmpty, map } from "lodash";
import {
  Badge,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
  Card,
  CardBody,
  Input,
} from "reactstrap";

// Import Components
import Breadcrumbs from "components/Common/Breadcrumb";
import ApproveModal from "./ApproveModal";
import DeclineModal from "./DeclineModal";

// Import API Helper
import getAllUsers from "common/realBackend/users/getAllUsers";
import getAllCandidates from "common/realBackend/candidates/getAllCandidates";
import updateUser from "common/realBackend/users/updateUser";

// Project Status component to display status badge
const ProjectStatus = ({ status }) => {
  switch (status) {
    case "pending":
      return <Badge className="bg-warning text-dark"> {status} </Badge>;

    case "declined":
      return <Badge className="bg-danger"> {status} </Badge>;

    case "approved":
      return <Badge className="bg-success"> {status} </Badge>;

    default:
      return <Badge className="bg-secondary"> {status} </Badge>;
  }
};

const PendingUsersAndCandidates = () => {
  document.title = "Pending Users and Candidates | Precheck Hire Admin";

  const [users, setUsers] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [approveModal, setApproveModal] = useState(false);
  const [declineModal, setDeclineModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [action, setAction] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch Users
        const usersResponse = await getAllUsers();
        const filteredUsers = usersResponse.data?.users.filter(
          (user) => user.kycVerificationStatus === "pending" || user.kycVerificationStatus === "declined"
        );
        setUsers(filteredUsers);

        // Fetch Candidates
        const candidatesResponse = await getAllCandidates();
        const filteredCandidates = candidatesResponse.filter(
          (candidate) => candidate.kycVerificationStatus === "pending" || candidate.kycVerificationStatus === "declined"
        );
        setCandidates(filteredCandidates);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleItemClick = (item, actionType, type) => {
    setSelectedItem({ ...item, type });
    setAction(actionType);
    actionType === "approve" ? setApproveModal(true) : setDeclineModal(true);
  };

  const handleAction = async () => {
    if (selectedItem) {
      try {
        // Create the status to be updated based on action
        const status = action === "approve" ? "approved" : "rejected";
        const updateData = { kycVerificationStatus: status };

        // Call the appropriate API based on the item type (User or Candidate)
        let response;
        if (selectedItem.type === "user") {
          response = await updateUser(selectedItem.id, updateData);
        } else if (selectedItem.type === "candidate") {
          response = await updateCandidate(selectedItem.id, updateData);
        }

        console.log(`Item ${action}d successfully:`, response);

        // Refetch data after updating
        await fetchData();
      } catch (error) {
        console.error(`Error updating ${selectedItem.type}:`, error);
      }
    }
    setApproveModal(false);
    setDeclineModal(false);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter combined users and candidates
  const filteredItems = [
    ...users
      .filter((user) => user.role !== "admin" && user.fullName.toLowerCase().includes(searchQuery.toLowerCase())),
    ...candidates
      .filter((candidate) => candidate.role !== "admin" && candidate.fullName.toLowerCase().includes(searchQuery.toLowerCase())),
  ];

  return (
    <React.Fragment>
      <ApproveModal
        show={approveModal}
        onApproveClick={handleAction}
        onCloseClick={() => setApproveModal(false)}
      />
      <DeclineModal
        show={declineModal}
        onDeclineClick={handleAction}
        onCloseClick={() => setDeclineModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Pending Users" breadcrumbItem="Pending Users" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <Input
                    type="text"
                    placeholder="Search by name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    style={{ width: '300px' }}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div>
                <div className="table-responsive">
                  <Table className="project-list-table table-nowrap align-middle table-borderless">
                    <thead>
                      <tr>
                        <th scope="col">Name and Email</th>
                        <th scope="col">Phone Number</th>
                        <th scope="col">Status</th>
                        <th scope="col">User Type</th>
                        <th scope="col">ID Type</th>
                        <th scope="col">ID Number</th>
                        <th scope="col">Issue Date</th>
                        <th scope="col">Expiry Date</th>
                        <th scope="col">ID Image Front</th>
                        <th scope="col">ID Image Back</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map(filteredItems, (item, index) => (
                        <tr key={index}>
                          <td>
                            <h5 className="text-truncate font-size-14">
                              <Link to="#" className="text-dark">
                                {item.fullName}
                              </Link>
                            </h5>
                            <p className="text-muted mb-0">{item.email}</p>
                          </td>
                          <td>{item.phoneNumber}</td>
                          <td>
                            <ProjectStatus status={item.kycVerificationStatus} />
                          </td>
                          <td>
                            {item.role === "candidate" ? "Job Seeker" : item.role === "user" ? "Employer" : item.role}
                          </td>
                          <td>{item.identityTypeName}</td>
                          <td>{item.identityNumber}</td>
                          <td>{new Date(item.identityIssueDate).toLocaleDateString()}</td>
                          <td>{new Date(item.identityExpiryDate).toLocaleDateString()}</td>
                          <td>
                            <a href={item.identityImageFront} target="_blank" rel="noopener noreferrer">
                              View Image
                            </a>
                          </td>
                          <td>
                            <a href={item.identityImageBack} target="_blank" rel="noopener noreferrer">
                              View Image
                            </a>
                          </td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle className="card-drop" tag="a">
                                <i className="mdi mdi-dots-horizontal font-size-18" />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem
                                  onClick={() => handleItemClick(item, "approve", item.type)}
                                >
                                  <i className="mdi mdi-check font-size-16 text-success me-1" />{" "}
                                  Approve
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => handleItemClick(item, "decline", item.type)}
                                >
                                  <i className="mdi mdi-close font-size-16 text-danger me-1" />{" "}
                                  Decline
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(PendingUsersAndCandidates);
