import axios from 'axios';
import { useHistory } from 'react-router-dom';

// Base URL of the API
const baseURL = 'https://api-stack-api.onrender.com/';

// Create an instance of Axios
const axiosInstance = axios.create({
  baseURL: baseURL,
});

// Add a request interceptor to attach the token to every request
axiosInstance.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem('authUser');
    if (user) {
      const token = JSON.parse(user).token;
      config.headers['Authorization'] = `Bearer ${token}`; // Attach token to headers
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.data) {
      const { status, message } = error.response.data;

      // Check if the error is related to token expiration
      if (status === 'fail' && message === 'Your token has expired! Please log in again.') {
        // Clear the authUser from localStorage
        localStorage.removeItem('authUser');

        // Redirect to the login page
        window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
