import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom"; // Import Link here
import { Button, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import getAllTransactions from "common/realBackend/transactions/getAllTransactions";
import { 
  OrderId, 
  BillingName, 
  BillingDetails, 
  FormattedDate, 
  Total, 
  PaymentStatus, 
  PaymentMethod 
} from "./LatestTranactionCol";
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";
import moment from 'moment';

const LatestTransaction = () => {
  const [latestTransactions, setLatestTransactions] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { orders } = useSelector((state) => ({
    orders: state.ecommerce.orders,
  }));

  useEffect(() => {
    const fetchLatestTransactions = async () => {
      try {
        const response = await getAllTransactions();
        const transactions = response.data.transactions;

        const latestSixTransactions = transactions
          .slice(-6)
          .map((transaction) => ({
            id: transaction.id,
            reference: transaction.reference,
            billingName: `${transaction.User.firstName} ${transaction.User.lastName}`,
            createdAt: moment(transaction.createdAt).format("DD MMM YYYY"),
            amount: transaction.amount,
            status: transaction.status,
            paymentMethod: transaction.paymentMethod,
            billingDetails: transaction.SubscriptionPlan
              ? transaction.SubscriptionPlan.name
              : "N/A",
          }));

        setLatestTransactions(latestSixTransactions);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchLatestTransactions();
  }, []);

  const handleOrderClick = (order) => {
    if (order.status === "failed") {
      setModalMessage("Cannot generate a receipt for a failed transaction.");
      setIsModalOpen(true);
    } else {
      navigate(`/invoices-detail/${order.id}`);
    }
  };

  const handlePrintOrder = (order) => {
    const printUrl = `/print-order/${order.reference}`;
    window.open(printUrl, "_blank");
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Trans ID",
        accessor: "id",
        width: "150px",
        filterable: true,
        Cell: (cellProps) => <OrderId {...cellProps} />,
      },
      {
        Header: "Billing Name",
        accessor: "billingName",
        filterable: true,
        Cell: (cellProps) => <BillingName {...cellProps} />,
      },
      {
        Header: "Billing Details",
        accessor: "billingDetails",
        filterable: true,
        Cell: (cellProps) => <BillingDetails {...cellProps} />,
      },
      {
        Header: "Date",
        accessor: "createdAt",
        filterable: true,
        Cell: (cellProps) => <FormattedDate {...cellProps} />,
      },
      {
        Header: "Total",
        accessor: "amount",
        filterable: true,
        Cell: (cellProps) => <Total {...cellProps} />,
      },
      {
        Header: "Payment Status",
        accessor: "status",
        filterable: true,
        Cell: (cellProps) => <PaymentStatus {...cellProps} />,
      },
      {
        Header: "Payment Method",
        accessor: "paymentMethod",
        Cell: (cellProps) => <PaymentMethod {...cellProps} />,
      },
      {
        Header: "View Details",
        accessor: "view",
        disableFilters: true,
        Cell: (cellProps) => (
          <Button
            type="button"
            color="primary"
            className="btn-sm btn-rounded"
            onClick={() => handleOrderClick(cellProps.row.original)}
          >
            View Details
          </Button>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: (cellProps) => (
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              onClick={() => {
                const orderData = cellProps.row.original;
                handlePrintOrder(orderData);
              }}
            >
              <i className="mdi mdi-printer font-size-18" id="printtooltip" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="mb-4 h4 card-title">Latest Transaction</div>
          <TableContainer
            columns={columns}
            data={latestTransactions}
            isGlobalFilter={false}
            isAddOptions={false}
            customPageSize={6}
          />
        </CardBody>
      </Card>

      {/* Modal to show error message */}
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Transaction Error</ModalHeader>
        <ModalBody>{modalMessage}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default LatestTransaction;
